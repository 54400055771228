/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

input,
textarea,
button,
body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}


// input,
// textarea,
// button {
// outline: none;
// border: none;
// }

h1,
h2,
h3,
h4 {
  color: var(--title-color);
  font-weight: var(--font-medium);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img,
svg {
  max-width: 100%;
  height: auto;
  display: block;
}