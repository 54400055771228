/*=============== GOOGLE FONTS ===============*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');

/*=============== VARIABLES CSS ===============*/
:root {
  --body-font: 'Poppins', sans-serif;
  --biggest-font-size: 2rem;
  --bigger-font-size: 1.25rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;
  --very-smaller-font-size: .625rem;

  /*========== Font weight ==========*/
  --font-regular: 400;
  --font-medium: 500;
  --font-semi-bold: 600;
  --font-bold: 700;
  --font-extra-bold: 800;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;

  /*========== Colors ==========*/
  --title-color: #000;
  --griss-claro: #ECECEC;
  --griss-medio: #AAA;
  --griss-ligth: #F4F4F4;
  --griss-input: #979797;
  --griss-medium: #DADADA;
  --black: #000;
  --black-2: #212322;
  --black-3: #414141;
  --blac-4: #12121D;
  --white: #FFF;
  --red-color: #DD1F26;
  --red-color-2: #E0050B;
  --blue-ligth: #3B82F6;
  --gris-btns: #727272;
  //color: #0D1413;
}

/*========== Responsive typography ==========*/
@media screen and (min-width: 850px) {
  :root {
    --biggest-font-size: 4rem;
    --bigger-font-size: 2rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
    --very-smaller-font-size: 0.75rem;
  }
}