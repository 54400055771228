.container {
  max-width: 850px;
  margin-inline: auto;
  padding-inline: 1rem;
  padding-block: 1.5rem;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.15);
  min-height: 100vh;
  background-color: var(--white);

  @media screen and (min-width: 850px) {
    padding-inline: 1.5rem;
    padding-block: 2rem;
  }
}